import { model } from '@telekomconsalting/dex-guru-model'
import { ReactComponent as Unknown } from 'app-images/icons/tokens/unknown.svg'
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartDataset,
  ChartOptions,
  Filler,
  LinearScale,
  LineController,
  LineElement,
  LogarithmicScale,
  PointElement,
  Scale,
  ScaleOptions,
  Tick,
  Tooltip,
  TooltipItem,
  TooltipModel,
} from 'chart.js'
import { merge } from 'lodash'
import { MutableRefObject, ReactElement } from 'react'
import ReactDOMServer from 'react-dom/server'

import { getAmmConfig } from '../../config/amm'
import { financialFormat } from '../../helpers/priceAndAmountHelpers'
import { ChartTooltipOptions, FormatDateChartType, TraderBalanceRowData } from '../../model'
import { changeFormatDateChart, getMarketDisplayNameBySymbols } from '../../utils'
import { CategoryIcon } from '../CategoryIcon/CategoryIcon'
import IconTokenComponent from '../IconTokenComponent'
import IconAmm from '../TraderProfile/IconAmm'

ChartJS.register(
  LineController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  PointElement,
  LineElement,
  BarElement,
  BarController,
  Tooltip,
  Filler
)

export const CHART_DEFAULT_LABEL_COLOR = '#545C66'
export const CHART_DEFAULT_GRID_COLOR = '#29313A'

export const valueFromLogarithmicBase = (value: number): number => {
  return isNaN(value) ? NaN : value - 1
}

export const datasetsToLogarithmicBase = (datasets: ChartDataset[]): ChartDataset[] => {
  const resultDatasets = datasets

  resultDatasets.forEach((dataset, idx) => {
    resultDatasets[idx].data = [...dataset.data].map((item) => {
      const value = item as number

      return isNaN(value) ? NaN : value + 1
    })
  })

  return resultDatasets
}

export const getIconToken = (token: TraderBalanceRowData): ReactElement => {
  return token.logoURI ? (
    <div className="token-ico token-ico--sm">
      <IconTokenComponent
        iconToken={token.logoURI[0]}
        symbol={token.symbols[0]}
        className="token-ico__image"
      />
    </div>
  ) : (
    <Unknown />
  )
}

export const getIconAMM = (amm: string): ReactElement => {
  const config = getAmmConfig(amm)
  return <IconAmm {...config} size="sm" />
}

// function to get compact tooltip innerHTML
export const getTooltipContentsCompact = (
  dataPoints: { raw: unknown }[],
  title?: string,
  currency?: string,
  logarithmic?: boolean
): string => {
  const titleDate = title
    ? changeFormatDateChart(parseInt(title), FormatDateChartType.long, true)
    : undefined

  return ReactDOMServer.renderToStaticMarkup(
    <>
      {titleDate && <div className="chartjs-tooltip__title">{titleDate}</div>}
      {dataPoints.map((item, idx) => {
        const value = item.raw
        const resultValue = logarithmic ? valueFromLogarithmicBase(value as number) : value

        return (
          <div className="chartjs-tooltip__value" key={`${idx}__${value}`}>
            {currency === 'USD' && <span className="sign">$</span>}
            <span className="value">
              {financialFormat(`${resultValue}`, { roundBigNumbersFrom: 'K' })}
            </span>{' '}
            {currency && currency !== 'USD' && <span className="sign">{currency}</span>}
          </div>
        )
      })}
    </>
  )
}

// function to get detailed tooltip innerHTML
export const getTooltipContentsDetailed = (
  dataPoints: { value: unknown; label: string | undefined; borderColor: unknown }[],
  tooltipOptions?: ChartTooltipOptions,
  title?: string,
  logarithmic?: boolean,
  isTotal?: boolean,
  period?: string
): string => {
  const max = dataPoints.reduce((a, b) => (a += (b.value as number) || 0), 0)
  const data = dataPoints.filter((item) => item.label !== 'noob')
  const other = dataPoints.filter((item) => item.label === 'noob')
  const titleDate = title
    ? changeFormatDateChart(parseInt(title), FormatDateChartType.long, true, period)
    : undefined

  return ReactDOMServer.renderToStaticMarkup(
    <>
      {titleDate && <div className="chartjs-tooltip__title">{titleDate}</div>}
      <div className="chartjs-tooltip-legends">
        {[...data, ...other].map((item, idx) => {
          const { label, value, borderColor } = item

          if (borderColor === 'transparent') {
            return null
          }

          const resultValue = (
            logarithmic ? valueFromLogarithmicBase(value as number) : value
          ) as number

          const isTokenLegend = label === 'Token Balance'

          const percent = (resultValue / max) * 100
          const percentDisplay =
            percent < 1 ? '~0' : percent > 99 && percent < 100 ? '~100' : Math.round(percent)

          return (
            <div
              className="chartjs-tooltip-legend"
              key={`${idx}__${value}`}
              style={
                {
                  '--dexguru-color': `${borderColor}`,
                } as React.CSSProperties
              }>
              <span className="chartjs-tooltip-legend__title">
                {(tooltipOptions?.isAmm || tooltipOptions?.isCategory) && label && (
                  <>
                    <div className="body">
                      {tooltipOptions?.isCategory && (
                        <span className="category">
                          {label === 'noob' ? (
                            <span className="other">Other</span>
                          ) : (
                            <span className="icon">
                              <CategoryIcon category={label as model.TraderCategoryName} />
                            </span>
                          )}
                        </span>
                      )}
                      <span className="value">
                        {tooltipOptions?.currency === 'USD' && <span className="sign">$</span>}
                        {financialFormat(resultValue, { roundBigNumbersFrom: 'K' })}{' '}
                        {tooltipOptions?.currency !== 'USD' && (
                          <span className="sign">{tooltipOptions?.currency}</span>
                        )}
                      </span>
                      {tooltipOptions?.isAmm && (
                        <span className="title">{getAmmConfig(label).displayName}</span>
                      )}
                    </div>
                    <span className="percent">{percentDisplay}%</span>
                  </>
                )}
                {!tooltipOptions?.isAmm && !tooltipOptions?.isCategory && label}
              </span>
              {!tooltipOptions?.isAmm && !tooltipOptions?.isCategory && (
                <span className="chartjs-tooltip-legend__value">
                  {isTokenLegend && tooltipOptions?.token && (
                    <span className="symbol">
                      {(tooltipOptions?.token?.symbols || [])
                        .map((symbol) => getMarketDisplayNameBySymbols({ symbol }))
                        .join('/')}
                    </span>
                  )}{' '}
                  {!isTokenLegend && tooltipOptions?.currency === 'USD' && (
                    <span className="sign">$</span>
                  )}
                  <span className="value">
                    {financialFormat(`${resultValue}`, { roundBigNumbersFrom: 'K' })}
                  </span>{' '}
                  {!isTokenLegend && tooltipOptions?.currency !== 'USD' && (
                    <>
                      <span className="sign">{tooltipOptions?.currency}</span>{' '}
                    </>
                  )}
                </span>
              )}
            </div>
          )
        })}
      </div>
      {isTotal && (
        <div className="chartjs-tooltip-total">
          <span>Total</span>
          <span>
            {tooltipOptions?.currency === 'USD' && <span className="sign">$</span>}
            {financialFormat(`${max}`, { roundBigNumbersFrom: 'K' })}
          </span>
        </div>
      )}
    </>
  )
}

export const getChartYScaleOptions = (
  init?: {
    logarithmic?: boolean
    display?: boolean
    position?: 'left' | 'right'
    fontSize?: number
    labelColor?: string
    labelPrefix?: string
    labelPostfix?: string
    gridColor?: string | false
    maxTicksLimit?: number
  },
  extend?: ScaleOptions
): ScaleOptions => {
  const {
    logarithmic = true,
    display = true,
    position = 'left',
    fontSize = 12,
    labelColor = CHART_DEFAULT_LABEL_COLOR,
    labelPrefix,
    labelPostfix,
    gridColor = CHART_DEFAULT_GRID_COLOR,
    maxTicksLimit = 6,
  } = init || {}

  const result = {
    display,
    type: logarithmic ? 'logarithmic' : 'linear',
    position,

    beginAtZero: logarithmic ? false : true,
    min: logarithmic ? 1 : undefined,

    ticks: {
      font: {
        family: '"IBM Plex Sans Condensed", sans-serif',
        size: fontSize,
      },
      color: labelColor,
      maxTicksLimit: maxTicksLimit,
      autoSkip: true,
      includeBounds: true,
      callback: function (tickValue: number): string {
        let val = tickValue as number

        if (logarithmic) {
          val = valueFromLogarithmicBase(val)
        }

        const value = financialFormat(val, {
          roundBigNumbersFrom: 'K',
        })
          .replace(/\.00([KMBT])$/, '$1')
          .replace(/\.0([KMBT])$/, '$1')

        return `${labelPrefix || ''}${value}${labelPostfix || ''}`
      },
    },
    grid: {
      drawBorder: false,
      drawOnChartArea: !!gridColor,
      drawTicks: false,
      color: gridColor || '',
    },
    afterBuildTicks: (scale: Scale): void => {
      const lastTick = scale.ticks[scale.ticks.length - 1]
      lastTick.value += 1
      lastTick.major = true

      scale.ticks = [...scale.ticks, lastTick]
    },
  }

  return merge(result, extend)
}

export const getChartXScaleOptions = (
  init?: {
    type?: string
    fontSize?: number
    labelColor?: string
    gridColor?: string | false
    period?: string
    tickFormatter?: (label: string) => string
  },
  extend?: ScaleOptions
): ScaleOptions => {
  const {
    type = 'line',
    fontSize = 12,
    labelColor = CHART_DEFAULT_LABEL_COLOR,
    gridColor = CHART_DEFAULT_GRID_COLOR,
    period,
    tickFormatter = (label: string): string =>
      changeFormatDateChart(parseInt(label), FormatDateChartType.short, false, period),
  } = init || {}

  const result: ScaleOptions = {
    ticks: {
      font: {
        family: '"IBM Plex Sans Condensed", sans-serif',
        size: fontSize,
      },
      color: labelColor,
      padding: 4,
      maxRotation: 0,
      minRotation: 0,
      autoSkip: true,
      autoSkipPadding: 16,
      callback: function (
        value: number | string,
        index: number,
        ticks: Tick[]
      ): string | undefined {
        const labelDisplay = tickFormatter(this.getLabelForValue(value as number))

        if (type === 'bar') {
          return labelDisplay
        }
        return ticks.length <= 6 && index !== ticks.length - 1
          ? labelDisplay
          : index % 2 === 1 && index !== ticks.length - 1
          ? labelDisplay
          : undefined
      },
    },
    grid: {
      drawBorder: false,
      drawOnChartArea: false,
      drawTicks: true,
      tickLength: 4,
      color: gridColor || '',
    },
  }

  return merge(result, extend)
}

// creating common options for compact charts
export const getChartOptionsCompact = (init: {
  chartTooltipRef: MutableRefObject<HTMLDivElement | null>
  currency?: string
  logarithmic?: boolean
}): ChartOptions => {
  const { chartTooltipRef, currency, logarithmic } = init

  const chartOptions = {
    responsive: true,
    devicePixelRatio: (window.devicePixelRatio || 1) * 2,
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false,
        enabled: false,
        // external tooltip uses callback from externalTooltipCompact
        external: externalTooltipCompact(chartTooltipRef, currency, logarithmic),
      },
      legend: {
        display: false,
      },
    },
    hover: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      y: getChartYScaleOptions({
        fontSize: 10,
        labelPrefix: currency ? (currency === 'USD' ? '$' : '') : '',
        labelPostfix: currency ? (currency === 'USD' ? '' : ` ${currency}`) : '',
        gridColor: CHART_DEFAULT_GRID_COLOR,
        maxTicksLimit: 4,
      }),
      x: getChartXScaleOptions({ fontSize: 10 }),
    },
    animation: {
      duration: 0,
    },
  }

  return chartOptions as ChartOptions
}

// creating common options for detailed charts
export const getChartOptionsDetailed = (
  init: {
    type: string
    stacked?: boolean
    chartTooltipRef: MutableRefObject<HTMLDivElement | null>
    tooltipOptions?: ChartTooltipOptions
    currency?: string
    logarithmic?: boolean
    gridColor?: string
    labelColor?: string
    isTotal: boolean
    period?: string
  },
  extend?: ChartOptions
): ChartOptions => {
  const {
    type,
    stacked,
    chartTooltipRef,
    tooltipOptions,
    currency,
    logarithmic,
    gridColor,
    labelColor,
    isTotal,
    period,
  } = init

  const labelPrefix = currency && currency === 'USD' ? '$' : ''
  const labelPostfix = currency && currency !== 'USD' ? ` ${currency}` : ''

  const chartOptions = {
    responsive: true,
    devicePixelRatio: (window.devicePixelRatio || 1) * 2,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false,
        enabled: false,
        // external tooltip uses callback from externalTooltipDetailed
        external: externalTooltipDetailed(
          type,
          chartTooltipRef,
          tooltipOptions,
          logarithmic,
          isTotal,
          period
        ),
      },
      legend: {
        display: false,
      },
    },

    hover: {
      mode: 'index',
      intersect: false,
    },

    scales: {
      y: getChartYScaleOptions(
        {
          logarithmic: logarithmic,
          labelPrefix: labelPrefix,
          labelPostfix: labelPostfix,
          labelColor: labelColor,
          gridColor: gridColor,
        },
        { stacked: stacked }
      ),
      x: getChartXScaleOptions(
        {
          type: type,
          labelColor: labelColor,
          gridColor: gridColor,
          period: period,
        },
        { stacked: stacked }
      ),
    },
    animation: {
      duration: 0,
    },
  }

  return merge(chartOptions, extend)
}

// callback to update content and position for external compact tooltip
export const externalTooltipCompact =
  (
    chartTooltipRef: MutableRefObject<HTMLDivElement | null>,
    currency?: string,
    logarithmic?: boolean
  ) =>
  (context: { chart: ChartJS; tooltip: TooltipModel<'line'> }): void => {
    const { chart, tooltip } = context

    const tooltipEl = chartTooltipRef.current

    if (!tooltipEl) {
      return
    }

    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = '0'
      return
    }

    tooltipEl.innerHTML = getTooltipContentsCompact(
      tooltip.dataPoints,
      tooltip.title[0],
      currency,
      logarithmic
    )

    const chartWidth = chart.width
    const tooltipRect = tooltipEl.getBoundingClientRect()
    const tooltipWidth = tooltipRect.width
    let diff = 0
    if (tooltip.caretX + tooltipWidth > chartWidth) {
      diff = tooltip.caretX + tooltipWidth / 2 - chartWidth
    }
    if (tooltip.caretX < tooltipWidth / 2) {
      diff = tooltip.caretX - tooltipWidth / 2
    }

    tooltipEl.style.left = `${tooltip.caretX - diff - tooltipRect.width / 2}px`
    tooltipEl.style.top = `${tooltip.caretY - tooltipRect.height - 8}px`
    tooltipEl.style.opacity = '1'
  }

// callback to update content and position for external detailed tooltip
export const externalTooltipDetailed =
  (
    type: string,
    chartTooltipRef: MutableRefObject<HTMLDivElement | null>,
    tooltipOptions?: ChartTooltipOptions,
    logarithmic?: boolean,
    isTotal?: boolean,
    period?: string
  ) =>
  (context: { chart: ChartJS; tooltip: TooltipModel<'line'> }): void => {
    const { chart, tooltip } = context

    const tooltipEl = chartTooltipRef.current

    if (!tooltipEl) {
      return
    }

    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = '0'
      return
    }

    const dataPoints = tooltip.dataPoints
      .filter((item: TooltipItem<'line'>) =>
        tooltipOptions?.skipEmpty ? parseFloat(`${item.raw}`) : true
      )
      .sort((a: TooltipItem<'line'>, b: TooltipItem<'line'>) => {
        return tooltipOptions?.isSorted
          ? parseFloat(`${b.raw}`) - parseFloat(`${a.raw}`)
          : parseFloat(`${a.raw}`)
      })
      .map((item) => {
        return {
          label: item.dataset.label,
          value: item.raw,
          borderColor: item.dataset.borderColor,
        }
      })

    tooltipEl.innerHTML = getTooltipContentsDetailed(
      dataPoints,
      tooltipOptions,
      tooltip.title[0],
      logarithmic,
      isTotal,
      period
    )

    const chartWidth = chart.width
    const tooltipRect = tooltipEl.getBoundingClientRect()
    tooltipEl.style.left =
      tooltip.caretX + tooltipRect.width > chartWidth
        ? tooltip.caretX - tooltipRect.width - 16 + 'px'
        : tooltip.caretX + 16 + 'px'

    if (type === 'bar') {
      tooltipEl.style.top = `${(tooltip.chart.height - tooltipRect.height) / 2}px`
    } else {
      const positionPoints = tooltip.dataPoints.map((item) => item.element.y || 0)
      const tooltipYMax = Math.max(...positionPoints)
      const tooltipYMin = Math.min(...positionPoints)
      tooltipEl.style.top = `${(tooltipYMax + tooltipYMin) / 2 - tooltipRect.height / 2}px`
    }
    tooltipEl.style.opacity = '1'
  }

export const getChartBackground = (
  background: string[] | string,
  chart: ChartJS | null
): CanvasGradient | string => {
  if (!chart) {
    return ''
  }

  const { ctx, chartArea } = chart

  if (!Array.isArray(background)) {
    return background
  }

  const gradient = ctx.createLinearGradient(0, 0, 0, chartArea.height)
  background.forEach((color, idx) => {
    gradient.addColorStop(idx / (background.length - 1), color)
  })

  return gradient
}
