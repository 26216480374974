import { Chart as ChartJS, ChartDataset, Plugin } from 'chart.js'

// ChartJS plugin: vertical line on hover for X-based values
export const pluginHoverVerticalLine = {
  id: 'hoverVerticalLine',
  beforeDraw: (chart: ChartJS<'line'>): void => {
    const activeElements = chart?.tooltip?.getActiveElements()

    if (!activeElements?.length) {
      return
    }

    const activePoint = activeElements[0].element
    const ctx = chart.ctx
    const x = activePoint.x
    const bottomY = chart.chartArea.bottom
    const topY = chart.chartArea.top
    // begin draw
    ctx.save()

    // draw line
    ctx.beginPath()
    ctx.moveTo(x, topY)
    ctx.lineTo(x, bottomY)
    ctx.lineWidth = 1
    ctx.strokeStyle = 'rgba(255, 255, 255, 0.2)'
    ctx.stroke()

    // end draw
    ctx.restore()
  },
}

type TStoredData = {
  datasetIndex: number
  hidden: boolean
  color: unknown
  value: number
  y: number
  base: number
}

interface IPluginBarValueSort extends Plugin {
  storedData: {
    data: TStoredData[]
  }[]
}

// ChartJS plugin: Bar chart values sort for every X-based segment
export const pluginBarValueSort: IPluginBarValueSort = {
  id: 'barValueSort',
  storedData: [],
  beforeDraw(chart: ChartJS<'bar'>): void {
    this.storedData = []

    chart.data.datasets.forEach((dataset: ChartDataset<'bar'>, datasetIndex: number) => {
      dataset.data.forEach((data, index) => {
        if (!this.storedData[index]) {
          this.storedData[index] = {
            data: [],
          }
        }

        this.storedData[index].data[datasetIndex] = {
          datasetIndex: datasetIndex,
          hidden: chart.getDatasetMeta(datasetIndex).hidden ? true : false,
          color: dataset.backgroundColor,
          value: dataset.data[index] || 0,
          y: chart.getDatasetMeta(datasetIndex).data[index].y,
          base: 0, // chart.getDatasetMeta(datasetIndex).data[index].base, // TODO: inspect cases if base property is nessecary
        }
      })
    })

    const chartTop = chart.scales['y'].top
    const max = chart.scales['y'].max
    const h = chart.scales['y'].height / max

    chart.data.datasets.forEach((dataset: ChartDataset<'bar'>) => {
      dataset.data.forEach((data, index) => {
        // sort data in bar stack by value
        this.storedData[index].data = Object.keys(this.storedData[index].data)
          .map((k) => this.storedData[index].data[parseInt(k)])
          .sort((a, b) => b.value - a.value)

        this.storedData[index].data.forEach((d: TStoredData, i: number) => {
          // calculate base value
          const storedKeys = Object.keys(this.storedData[index].data)
          const reducedVal = storedKeys
            .map((k) => this.storedData[index].data[parseInt(k)].value)
            .reduce((a, b) => a + b, 0)
          const reducedValFiltered = storedKeys
            .map((k) => this.storedData[index].data[parseInt(k)])
            .filter((d) => d.hidden)
            .reduce((a, b) => a + b.value, 0)

          d.base = chartTop + (max - reducedVal) * h + reducedValFiltered * h

          // increase base value with values of previous records
          for (let j = 0; j < i; j++) {
            d.base += this.storedData[index].data[j].hidden
              ? 0
              : h * this.storedData[index].data[j].value
          }

          // set y value
          d.y = d.base + h * d.value
        })
      })
    })
  },

  beforeDatasetDraw(chart: ChartJS<'bar'>, args: { index: number }): void {
    // TODO: try to find other way to get rid of any. In case of using type ChartJS.Element data.y and data.base a marked as readonly.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chart.getDatasetMeta(args.index).data.forEach((data: any, index: number) => {
      const el = this.storedData[index].data.filter(
        (e: TStoredData) => e.datasetIndex === args.index
      )[0]
      data.y = el.y
      data.base = el.base
    })
  },
}
